<template>
  <v-card elevation="2" outlined class="mx-auto">
    <v-card-actions class="px-4">
      <v-card-subtitle class="d-inline-flex"
        ><v-icon class="mr-1" color="red" x-large>mdi-alert-outline</v-icon>
        <span style="color: red"
          >※既に梱包済みの案件の重量には反映されません。<br />※再計算する場合、再度梱包明細画面より更新して下さい。</span
        >
      </v-card-subtitle>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-actions>
    <base-table
      :columnName="[
        'item.boxName',
        'item.packagingMaterialsWeight',
        'item.height',
        'item.width',
        'item.depth',
        'item.memo',
        'item.edit',
      ]"
      :headers="headers"
      :items="boxTypes"
      :items-per-page-options="[50, 100, 150, 200, -1]"
      :per-page="100"
      :search="search"
    >
      <!-- boxName -->
      <template v-slot:[`item.boxName`]="{ item, index }">
        <v-textarea
          v-if="editing && editing.index === index"
          filled
          hide-details="true"
          :value="item.boxName"
          @change="changeDraftString({ key: 'boxName', value: $event, index })"
        ></v-textarea>
        <span v-else>{{ item.boxName }}</span>
      </template>
      <!-- packagingMaterialsWeight -->
      <template v-slot:[`item.packagingMaterialsWeight`]="{ item, index }">
        <v-text-field
          type="number"
          v-if="editing && editing.index === index"
          filled
          hide-details="true"
          :value="item.packagingMaterialsWeight"
          @change="
            changeDraftNumber({
              key: 'packagingMaterialsWeight',
              value: $event,
              index,
            })
          "
        ></v-text-field>
        <td v-else style="text-align: right">
          <span>{{ item.packagingMaterialsWeight.commaString() }}</span>
        </td>
      </template>
      <!-- height -->
      <template v-slot:[`item.height`]="{ item, index }">
        <v-text-field
          type="number"
          v-if="editing && editing.index === index"
          filled
          hide-details="true"
          :value="item.height"
          @change="changeDraftNumber({ key: 'height', value: $event, index })"
        ></v-text-field>
        <td v-else style="text-align: right">
          <span>{{ item.height.commaString() }}</span>
        </td>
      </template>
      <!-- width -->
      <template v-slot:[`item.width`]="{ item, index }">
        <v-text-field
          type="number"
          v-if="editing && editing.index === index"
          filled
          hide-details="true"
          :value="item.width"
          @change="changeDraftNumber({ key: 'width', value: $event, index })"
        ></v-text-field>
        <td v-else style="text-align: right">
          <span>{{ item.width.commaString() }}</span>
        </td>
      </template>
      <!-- depth -->
      <template v-slot:[`item.depth`]="{ item, index }">
        <v-text-field
          type="number"
          v-if="editing && editing.index === index"
          filled
          hide-details="true"
          :value="item.depth"
          @change="changeDraftNumber({ key: 'depth', value: $event, index })"
        ></v-text-field>
        <td v-else style="text-align: right">
          <span>{{ item.depth.commaString() }}</span>
        </td>
      </template>
      <!-- memo -->
      <template v-slot:[`item.memo`]="{ item, index }">
        <v-textarea
          v-if="editing && editing.index === index"
          filled
          hide-details="true"
          :value="item.memo"
          @change="changeDraftString({ key: 'memo', value: $event, index })"
        ></v-textarea>
        <span v-else v-html="htmlText(item.memo)"></span>
      </template>
      <!-- edit -->
      <template v-slot:[`item.edit`]="{ index }">
        <div v-if="role">
          <!-- startEditing -->
          <v-btn
            v-if="!editing || editing.index !== index"
            text
            icon
            color="blue lighten-2"
            @click="startEditing(index)"
          >
            <v-icon large>mdi-pencil-box</v-icon>
          </v-btn>
          <!-- saveBoxType -->
          <v-btn v-else text icon color="green lighten-2" @click="apiUpdateBoxType(index)">
            <v-icon large>mdi-content-save</v-icon>
          </v-btn>
          <!-- cancelEditing -->
          <v-btn v-if="editing && editing.index === index" text icon color="red lighten-2" @click="cancelEditing()">
            <v-icon large>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </template>
    </base-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  async created() {
    this.role = await this.getRole({ page: this.path.BOX_TYPE_MASTER, authority: this.user.authority });
  },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      search: '',
      role: false,
    };
  },
  computed: {
    ...mapGetters({
      boxTypes: 'boxTypeMaster/boxTypes',
      editing: 'boxTypeMaster/editing',
      labels: 'defined/labels',
      messages: 'defined/messages',
      clickedConfirm: 'ui/clickedConfirm',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),

    headers() {
      const { BOX_TYPE, NAME, PACKAGING_MATERIALS_WEIGHT, HEIGHT, WIDTH, DEPTH, DISCRIPTION } = this.labels;
      return [
        { text: BOX_TYPE, value: 'boxType', width: '60px' },
        { text: NAME, value: 'boxName', align: 'start', width: '300px' },
        {
          text: `${PACKAGING_MATERIALS_WEIGHT}（ g ）`,
          value: 'packagingMaterialsWeight',
          width: '140px',
        },
        { text: `${HEIGHT}（ cm ）`, value: 'height', width: '120px' },
        { text: `${WIDTH}（ cm ）`, value: 'width', width: '120px' },
        { text: `${DEPTH}（ cm ）`, value: 'depth', width: '120px' },
        { text: DISCRIPTION, value: 'memo', align: 'start' },
        { text: '', value: 'edit', width: '110px' },
      ].map((h) => ({ ...h, sortable: false }));
    },
  },
  methods: {
    ...mapActions({
      apiListBoxType: 'api/listBoxType',
      apiUpdateBoxType: 'api/updateBoxType',
      getRole: 'common/getRole',
    }),
    ...mapMutations({
      changeDraftString: 'boxTypeMaster/changeDraftString',
      changeDraftNumber: 'boxTypeMaster/changeDraftNumber',
      startEditing: 'boxTypeMaster/startEditing',
      cancelEditing: 'boxTypeMaster/cancelEditing',
    }),
    async initialize() {
      await this.apiListBoxType();
    },
    htmlText(msg) {
      return msg?.replace(/\r?\n/g, '<br>');
    },
  },
};
</script>
